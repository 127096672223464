$(document).ready( function() {

    $('.aktuelles__slider').slick({
        dots: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    });

    $('.jumbo-slider.jumbo-slider--container').slick({
        slidesToShow: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
        speed: 2000,
        arrows: false
    });

    $('.jumbo-slider.jumbo-slider--full').slick({
        slidesToShow: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
        speed: 2000,
        arrows: true,
        prevArrow: "<button type=\"button\" data-role=\"none\" class=\"slick-prev slick-arrow\" aria-label=\"Previous\" role=\"button\" style=\"display: block;\"><i class=\"fa fa-angle-left\"></i></button>",
        nextArrow: "<button type=\"button\" data-role=\"none\" class=\"slick-next slick-arrow\" aria-label=\"Next\" role=\"button\" style=\"display: block;\"><i class=\"fa fa-angle-right\"></i></button>"
    });

    $(window).resize(function() {
        if (window.innerWidth > 767) {
            $('.search__btn').on('click', function () {
                $(this).prev('input').animate({
                    width: "toggle"
                }, 350);
                $(this).prev('input').focus();
            });
            $(document).click(function(event) {
                if(!$(event.target).closest('form.search').length && !$(event.target).is('form.search')) {
                    if($('.searchterm').is(":visible")) {
                        $('.searchterm').animate({
                            width: "toggle"
                        }, 350);
                    }
                }
            });
        }
        window.setTimeout(function() {
            $('.image-holder').each(function() {
                $(this).width($(this).find('img').width());
            });
        }, 500);
    }).resize();

});

var shrinkHeader = 90;
$(window).scroll(function() {
    var scroll = getCurrentScroll();
    if ( scroll >= shrinkHeader ) {
        $('nav.navbar').addClass('fixed');
    }
    else {
        $('nav.navbar').removeClass('fixed');
    }
});

function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

function checkForm(i) {
    if(document.forms[i].searchterm.value=='') {
        return(false);
    }
}
